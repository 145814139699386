import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { PageMetaDataPropTypes } from 'types';

import { Prices, PricesProps } from 'components/Prices/Prices';
import { Layout } from 'components/Layout/Layout';

const PricesPage = ({
  data: {
    markdownRemark: {
      // eslint-disable-next-line react/prop-types
      frontmatter: { pageDescription, pageKeywords, pageTitle, ...rest },
    },
  },
}) => (
  <Layout
    description={pageDescription}
    keywords={pageKeywords}
    title={pageTitle}
  >
    <Prices {...rest} />
  </Layout>
);

PricesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        ...PageMetaDataPropTypes,
        ...PricesProps,
      }),
    }),
  }).isRequired,
};

export default PricesPage;

export const pricesPageQuery = graphql`
  query PricesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...PageMetaDataFragment
      ...PricesPageFragment
    }
  }
`;
