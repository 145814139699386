import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { FluidImagePropTypes } from 'types';

import { PageSection } from 'components/common/PageSection/PageSection';
import { SecondaryHeader } from 'components/common/Typography';
import { Image } from 'components/common/Image/Image';
import { Fade } from 'components/common/Animations';

import { PricesWrapper } from './PricesStyles';

export const Prices = ({ image, title }) => (
  <>
    <Image
      alt={title}
      as={image?.childImageSharp?.fluid ? Image : 'img'}
      fluid={image?.childImageSharp?.fluid}
      src={image}
    />
    <PageSection>
      <PricesWrapper>
        <Fade>
          <SecondaryHeader isBolded isUnderlined>
            {title}
          </SecondaryHeader>
        </Fade>
        <Fade>
          <div>This is prices body</div>
        </Fade>
      </PricesWrapper>
    </PageSection>
  </>
);

export const PricesProps = {
  image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string])
    .isRequired,
  title: PropTypes.string.isRequired,
};

Prices.propTypes = PricesProps;

export const pricesPageFragment = graphql`
  fragment PricesPageFragment on MarkdownRemark {
    frontmatter {
      title
      image {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;
